import Link from 'next/link';
import { useRouter } from 'next/router';

import { FAIcon } from '~/components/utils/FAIcons';

export default function Custom404() {
	const router = useRouter();

	return (
		<div className="flex w-full h-full">
			<div className="w-full m-auto text-center">
				<FAIcon icon="block-question" className="mr-2 -ml-2 text-8xl text-glazier-blue-800" />
				<h1 className="text-3xl md:text-4xl text-glazier-blue-800 font-bold mt-4">404 - Page Not Found</h1>
				<p className="text-2xl md:text-3xl my-8">
					No page found at <span className="border border-gray-400 bg-gray-100 px-2 py-1"> {router.asPath} </span>
				</p>
				<div className="space-x-4 md:space-x-6">
					<button className="black-button-xl" onClick={() => router.back()} type="button">
						<FAIcon icon="chevron-left" className="-ml-3 mr-3" />
						Back
					</button>
					<Link href="/" legacyBehavior>
						<button className="black-button-xl items-center" type="button">
							<FAIcon icon="house" className="-ml-3 mr-3" />
							Home
						</button>
					</Link>
				</div>
			</div>
		</div>
	);
}
